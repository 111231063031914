/**
 * (c) David Giesemann
 * info@david-giesemann.de
 */

import "./index.scss"

import CustomScrollbar from "./modules/CustomScrollbar";

let scroller = null

const init = () => {
    const element = document.querySelector("#manufacturers")

    if(!element) return;

    if(scroller) scroller.scrollContainer.remove()

    if(element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) scroller = new CustomScrollbar(element,{
        scrollBarColor: "#cc0000"
    })
}

window.addEventListener("DOMContentLoaded",init)
window.addEventListener("resize",init)

function scrollByButton(e){
    const tourNavButton = e.target.closest(".button--prev, .button--next");

    if(!tourNavButton) return;

    const scroller = document.querySelector('.tour')
    const slides = Array.from(scroller.children)

    let dist = slides.at(1).getBoundingClientRect().left - slides.at(0).getBoundingClientRect().left

    if(tourNavButton.classList.contains('button--prev'))
        dist *= -1

    scroller.scrollBy({
        top: 0,
        left: dist,
        behavior: 'smooth'
    })
}

addEventListener('click',scrollByButton)

const returnDiv = document.createElement('div')

async function submitForm(e){
    if(!e.target || !e.target.matches('.workshop-form')) return;

    e.preventDefault()

    try{
        const data = new FormData(e.target)
        const req = await fetch('/controller/mail.php',{
            method: 'POST',
            body: data
        })
        const res = await req.json()

        returnDiv.className = 'alert alert--success'
        returnDiv.innerHTML = res.return

        e.target.append(returnDiv)
        e.target.setAttribute('inert','')
    } catch (e) {
        console.log(e)

        returnDiv.className = 'alert alert--error'
        returnDiv.innerHTML = 'Etwas ist schiefgegangen! Bitte versuchen Sie es später erneut oder kontaktieren Sie uns per E-Mail oder Telefon!'

        e.target.append(returnDiv)
        e.target.setAttribute('inert','')
    }
}

addEventListener('submit',submitForm)